import React, { useRef, useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Player } from "video-react";
import gcDemoVideo from "../../assets/videos/GC_demo.mp4";
import gcPromoVideo from "../../assets/videos/GC_demo_2.mp4";
import VideoPlayer from "../../Combonent/videoPlayer";

const HomeStrip = () => {
  const [fmModal, setFmModal] = useState(false);
  const [smModal, setSmModal] = useState(false);
  const [scrolling, setScrolling] = useState(true);
  const [playingIndex, setPlayingIndex] = useState(null);

  const videos = [

    {
      showHead: "Watch Demo Video",
      src: gcDemoVideo

    },
    {
      showHead: "Watch Promo Video ",
      src: gcPromoVideo
    },
    // Add more video sources as needed
  ];



  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolling(false);
      } else {
        setScrolling(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`your-default-class ${scrolling ? "isSticky" : ""}`}>
      <Modal
        size="lg"
        show={fmModal}
        onHide={() => setFmModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Customer Portal - Login
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div class="modal-body">
              <div class="modal-body-inner d-flex justify-content-center align-items-center">
                <div class="row">
                  <div class="col-lg-4 mb-2">
                    <a
                      class="btn"
                      href="https://cp.onlinetracking.co/#/login/1"
                      target="_blank"
                    >
                      Login
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">

                {videos.map((video, index) => (

                  <div className="col-lg-6">
                    <h4>{video.showHead}</h4>
                    <VideoPlayer
                      key={index}
                      src={video.src}
                      isPlaying={playingIndex === index}
                      onPlay={() => handlePlay(index)}
                    />
                  </div>
                ))}

                {/* <div className="col-lg-6">
                  <video width="100%" controls height="auto" src={videos}  type="video/mp4" />

                </div>
                <div className="col-lg-6">
              
                  <video width="100%" controls height="auto" src={gcPromoVideoSec} type="video/mp4" />
                 
                </div> */}
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={smModal}
        onHide={() => setSmModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Partner Portal - Please Select Your Partner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body d-flex justify-content-center align-items-center">
            <div className="row">
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://pp.onlinetracking.co/auth/login/54"
                  target="_blank"
                >
                  Singapore
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/101"
                  target="_blank"
                >
                  Pakistan{" "}
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/232"
                  target="_blank"
                >
                  Bangladesh
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/97"
                  target="_blank"
                >
                  SriLanka
                </a>
              </div>
              <div className="col-lg-4 mb-2">
                <a
                  className="btn"
                  href="https://cp.onlinetracking.co/#/login/87"
                  target="_blank"
                >
                  Myanmar
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <section className="under-strip">
        <div className="container">
          <div className="strip-wrapper">
            <div className="strip-card">
              <button onClick={() => setFmModal(true)}>
                <i className="fa fa-users"></i> Customer Portal
              </button>
            </div>
            <div className="strip-card">
              {/* <button onClick={() => setSmModal(true)}>
                <i className="fa fa-user-circle"></i> Partner Portal
              </button> */}
              <a
                href="https://pp.onlinetracking.co/auth/login/1"
                target="_blank"
              >
                <i className="fa fa-user-circle"></i> Partner Portal
              </a>
            </div>
            <div className="strip-card">
              <a
                href="
http://ec2-13-229-38-56.ap-southeast-1.compute.amazonaws.com:8081/ords/f?p=107:104:::::P0_GROUP_RID:54"
                target="_blank"
              >
                <i className="fa fa-compress"></i> Sailing Schedule
              </a>
            </div>
            <div className="strip-card">
              <a
                href="
http://ec2-13-229-38-56.ap-southeast-1.compute.amazonaws.com:8081/ords/f?p=107:102:::::P0_GROUP_RID:54"
                target="_blank"
              >
                <i className="fa fa-ship"></i> Tracking
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeStrip;
